import { Vue, Component} from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from '@/utilities/commonFunctions';

@Component({})
export default class DataDashboardWelcome extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    mobileView: boolean = false;
    
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    mounted() {
      this.mobileView = document.documentElement.clientWidth <= 768
        ? true
        : false;
      window.addEventListener('resize', APP_UTILITIES.debounce(this.isMobile));
    }
    
    destroyed() {
      window.removeEventListener('resize', this.isMobile);
    }

    isMobile(){
      this.mobileView =APP_UTILITIES.isMobile();
    }

    close(){
      this.$emit('close', {id:'quality-welcome'});
    }
}